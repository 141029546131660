import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { routes } from '@src/routes/routes';

export default function Footer() {
  const router = useRouter();
  // Don't show footer for message related page
  if (router.asPath.match(routes.userMessageRooms())) {
    return <></>;
  }
  return (
    <footer className="flex flex-col bg-gray-600">
      <div className="px-6 pt-8 pb-4">
        <div className="space-y-4 text-white">
          <Link href={routes.privacyPolicy()}>
            <a className="block text-white">プライバシーポリシー</a>
          </Link>
          <Link href={routes.termsOfUse()}>
            <a className="block text-white">利用規約</a>
          </Link>
          <Link href={routes.company()}>
            <a className="block text-white">運営会社</a>
          </Link>
          <Link href={routes.contact()}>
            <a className="block text-white">お問い合わせ</a>
          </Link>
        </div>
        <div className="mt-2.5 text-sm text-[#BDBDBD]">
          Copyright©︎ojiiy, Inc. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}
