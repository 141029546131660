export const SITE_ROOT_URL =
  process.env.NEXT_PUBLIC_SITE_ROOT_URL || 'http://localhost:3000';

export const GRAPHQL_API_URL =
  process.env.NEXT_PUBLIC_GRAPHQL_API_URL || 'http://localhost:8000';

export const LINE_LOGIN_URL = `${GRAPHQL_API_URL}/auth/oauth?provider=line`;
export const LOGOUT_URL = `${GRAPHQL_API_URL}/auth/logout`;

export const saveProjectContentUrl = (projectId: string) =>
  `${GRAPHQL_API_URL}/owner/projects/${projectId}/content`;

export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
