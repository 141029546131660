export const routes = {
  top: () => '/',
  home: () => '/home',
  signup: () => '/sign_up',
  signupWithOriginalPath: (originalPath: string) =>
    `/sign_up?originalUrl=${originalPath}`,
  login: () => '/login',
  loginWithOriginalPath: (originalPath: string) =>
    `/login?originalUrl=${originalPath}`,
  registration: () => '/user/registration',
  ownerRegistration: () => '/user/owner_registration',
  userProfile: () => '/user/profile',
  userProfileEdit: () => '/user/profile/edit',
  userProjects: () => '/user/projects',
  projectDetail: (projectId: string) => `/projects/${projectId}`,
  projectEdit: (projectId: string) => `/projects/${projectId}/edit`,
  userMessageRooms: () => '/user/message_rooms',
  userMessageRoomDetail: (messageRoomId: string) =>
    `/user/message_rooms/${messageRoomId}`,

  privacyPolicy: () => '/privacy_policy',
  termsOfUse: () => '/terms_of_use',
  company: () => '/company',
  contact: () => '/contact',
};
