import gql from 'graphql-tag';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import {
  HeaderComponentViewerQuery,
  useHeaderComponentViewerQuery,
} from '@src/generated/graphql';
import { routes } from '@src/routes/routes';

gql`
  query headerComponentViewer {
    viewer {
      id
      nickname
      avatarUrl
      owner {
        id
      }
    }
  }
`;

const stickyHeaderDisablePaths = [
  routes.registration(),
  routes.projectDetail('[projectId]'),
];

export default function Header() {
  const router = useRouter();
  const { data, loading } = useHeaderComponentViewerQuery();
  const user = data?.viewer;

  const isStickyHeaderDisabled = stickyHeaderDisablePaths.some(
    (v) => router.pathname === v
  );

  return (
    <header className={`top-0 z-1 ${isStickyHeaderDisabled || 'sticky'}`}>
      <div className="flex justify-between md:justify-start items-center sm:px-6 pr-4 pl-8 mx-auto md:space-x-10 h-[72px] bg-white border-b-[1px] border-gray-200">
        <div className="flex lg:flex-1 justify-start lg:w-0">
          <Link href={user ? routes.home() : routes.top()}>
            <a>
              <svg
                width="78"
                height="19"
                viewBox="0 0 78 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.26172 18H0.746094V0.9375H4.26172V18ZM20.9258 12.3164C20.793 14.1523 20.1133 15.5977 18.8867 16.6523C17.668 17.707 16.0586 18.2344 14.0586 18.2344C11.8711 18.2344 10.1484 17.5 8.89062 16.0312C7.64062 14.5547 7.01562 12.5312 7.01562 9.96094V8.91797C7.01562 7.27734 7.30469 5.83203 7.88281 4.58203C8.46094 3.33203 9.28516 2.375 10.3555 1.71094C11.4336 1.03906 12.6836 0.703125 14.1055 0.703125C16.0742 0.703125 17.6602 1.23047 18.8633 2.28516C20.0664 3.33984 20.7617 4.82031 20.9492 6.72656H17.4336C17.3477 5.625 17.0391 4.82812 16.5078 4.33594C15.9844 3.83594 15.1836 3.58594 14.1055 3.58594C12.9336 3.58594 12.0547 4.00781 11.4688 4.85156C10.8906 5.6875 10.5938 6.98828 10.5781 8.75391V10.043C10.5781 11.8867 10.8555 13.2344 11.4102 14.0859C11.9727 14.9375 12.8555 15.3633 14.0586 15.3633C15.1445 15.3633 15.9531 15.1172 16.4844 14.625C17.0234 14.125 17.332 13.3555 17.4102 12.3164H20.9258ZM37.1211 18H33.6055V10.6875H26.75V18H23.2344V0.9375H26.75V7.85156H33.6055V0.9375H37.1211V18ZM43.9414 18H40.4258V0.9375H43.9414V18ZM53.5742 12.0586H46.9766V9.32812H53.5742V12.0586ZM60.2539 18H56.7383V0.9375H60.2539V18ZM77.3984 18H73.8828L67.0391 6.77344V18H63.5234V0.9375H67.0391L73.8945 12.1875V0.9375H77.3984V18Z"
                  fill="#333333"
                />
              </svg>
            </a>
          </Link>
        </div>
        <RightNav user={user} loading={loading} />
      </div>
    </header>
  );
}

const RightNav = ({
  user,
  loading,
}: {
  user?: HeaderComponentViewerQuery['viewer'];
  loading: boolean;
}) => {
  const router = useRouter();
  // Don't show navigation when a user is on the registration page
  // since we don't want the user to leave.
  if (loading || router.asPath == routes.registration()) {
    return <></>;
  }
  if (user) {
    return <UserMenu />;
  }
  return (
    <div className="flex flex-1 justify-end items-center">
      <Link href={routes.signup()}>
        <a>
          <div className="flex flex-col items-center">
            <button className="inline-block py-1 px-2 font-bold text-white bg-primary-500 rounded-[0.25rem] border-2 border-primary-500">
              新規登録
            </button>
          </div>
        </a>
      </Link>
      <Link href={routes.login()}>
        <a className="text-gray-700">
          <div className="flex flex-col items-center ml-2">
            <button className="py-1 px-2 font-bold rounded-[0.25rem] border-2 border-black inline-gray-700">
              ログイン
            </button>
          </div>
        </a>
      </Link>
    </div>
  );
};

const UserMenu = () => {
  return (
    <div className="flex flex-1 justify-end items-center">
      <Link href={routes.userMessageRooms()}>
        <a className="text-gray-700">
          <div className="flex flex-col items-center">
            <MessageIcon />
            <div className="mt-2 text-sm tracking-tightest text-center">
              メッセージ
            </div>
          </div>
        </a>
      </Link>
      <Link href={routes.userProfile()}>
        <a className="text-gray-700">
          <div className="flex flex-col items-center ml-2">
            <ProfileIcon />
            <div className="mt-2 text-sm tracking-tightest text-center">
              プロフィール
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

function MessageIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M5.78587 18.39V7.771H2.70481C1.21556 7.771 0 9.01331 0 10.5292V23.4317C0 24.9476 1.21556 26.19 2.70481 26.19H6.57981V29.0318C6.57981 29.2219 6.69381 29.3929 6.86475 29.4651C7.03575 29.5372 7.23706 29.4954 7.36619 29.3625L10.4814 26.19H17.1373C18.6266 26.19 19.8423 24.9476 19.8423 23.4317V23.4279H10.7664C8.01962 23.4279 5.78587 21.1672 5.78587 18.39Z"
          fill="#4B4B4B"
        />
        <path
          d="M28.9198 2.5H10.7662C9.07216 2.5 7.68604 3.91187 7.68604 5.63731V18.3896C7.68604 20.1152 9.07216 21.5271 10.7662 21.5271H21.058L25.7672 26.2791C25.9172 26.4317 26.1425 26.4774 26.3382 26.3949C26.534 26.3122 26.6617 26.1177 26.6617 25.9019V21.5271H28.9198C30.614 21.5271 32 20.1152 32 18.3896V5.63731C32 3.91187 30.6138 2.5 28.9198 2.5ZM23.0722 17.5072H12.245V15.9874H23.0722V17.5072ZM27.4411 12.7581H12.245V11.2384H27.4411V12.7581ZM27.4411 8.00894H12.245V6.48925H27.4411V8.00894Z"
          fill="#4B4B4B"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function ProfileIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.16331 0 0 7.1635 0 15.9998C0 24.8364 7.16331 32 16 32C24.8366 32 32 24.8364 32 15.9998C32 7.1635 24.8367 0 16 0ZM15.9998 25.1195C11.6718 25.1176 8.57981 24.5296 8.652 23.9909C9.04519 21.0466 11.4194 20.7635 12.9271 19.5571C13.6041 19.0155 13.9332 19.1509 13.9332 18.2481C13.9332 17.9322 13.9332 17.3452 13.9332 16.8036C13.5267 16.3524 13.379 16.4088 13.0353 14.6372C12.3408 14.6372 12.3856 13.7343 12.0579 12.9338C11.8023 12.3098 11.9962 12.0404 12.2106 11.9234C12.0949 11.3066 12.168 9.23531 13.0564 8.15725L12.9163 7.17919C12.9163 7.17919 13.1956 7.24925 13.8941 7.10906C14.5929 6.96988 16.2694 6.55094 17.2475 7.38888C19.5948 7.734 20.2181 9.66187 19.7603 11.9093C19.9851 12.0172 20.2089 12.2819 19.9416 12.9338C19.6142 13.7343 19.6589 14.6372 18.9644 14.6372C18.6209 16.4088 18.473 16.3524 18.0666 16.8036C18.0666 17.3452 18.0666 17.9322 18.0666 18.2481C18.0666 19.1509 18.3954 19.0155 19.0724 19.5571C20.5802 20.7634 22.9543 21.0466 23.3477 23.9909C23.4197 24.5296 20.3279 25.1176 15.9998 25.1195Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
