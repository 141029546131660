import { ApolloProvider } from '@apollo/client';
import { AppProps } from 'next/app';
import React from 'react';
import '../styles/globals.css';

import ErrorBoundary from '@src/components/ErrorBoundary';
import Footer from '@src/components/Footer';
import Header from '@src/components/Header';
import { ToastProvider } from '@src/components/Toast';
import apolloClient from '@src/lib/apolloClient';
import { usePageView } from '@src/lib/gtag';

export default function MyApp({ Component, pageProps }: AppProps) {
  usePageView();

  return (
    <div className="flex flex-col min-h-full">
      <ApolloProvider client={apolloClient}>
        <ToastProvider>
          <ErrorBoundary>
            <Header />
            <main className="relative z-0 flex-grow">
              <Component {...pageProps} />
            </main>
            <Footer />
          </ErrorBoundary>
        </ToastProvider>
      </ApolloProvider>
    </div>
  );
}
