import {
  ApolloClient,
  ApolloLink,
  ApolloError,
  InMemoryCache,
} from '@apollo/client';
import { withScalars } from 'apollo-link-scalars';
import { createUploadLink } from 'apollo-upload-client';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { DateTimeResolver } from 'graphql-scalars';
import fetch from 'isomorphic-unfetch';
import { NextRouter } from 'next/router';

import introspectionResult from '../../graphql.schema.json';

import { GRAPHQL_API_URL } from '@src/config/env';
import { ErrorCode } from '@src/generated/graphql';
import { routes } from '@src/routes/routes';

const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery
);
const typesMap = {
  Date: DateTimeResolver,
};
const link = ApolloLink.from([
  withScalars({ schema, typesMap }) as unknown as ApolloLink,
  createUploadLink({
    uri: `${GRAPHQL_API_URL}/query`,
    credentials: 'include',
    fetch,
    headers: {
      'X-Requested-By': 'ichi-in-frontend', // for CSRF validation
    },
  }),
]);

const apolloClient = new ApolloClient({
  ssrMode: true,
  link,
  cache: new InMemoryCache(),
});

export default apolloClient;

export function handleApolloError(error: ApolloError, router: NextRouter) {
  for (const e of error.graphQLErrors) {
    if (e.extensions?.code === ErrorCode.Unauthenticated) {
      router.push(routes.signupWithOriginalPath(router.asPath));
      return;
    }
    if (e.extensions?.code === ErrorCode.NotRegistered) {
      router.push(routes.registration());
      return;
    }
  }
  throw error;
}
