import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  Time: any;
  Upload: any;
};

export type AdminPublishProjectInput = {
  projectId: Scalars['ID'];
};

export type City = Node & {
  id: Scalars['ID'];
  prefectureId: Scalars['ID'];
  prefecture: Prefecture;
  name: Scalars['String'];
  hiragana_name: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CreateProjectInterest = {
  projectId: Scalars['ID'];
};

export type CurrentTempUserProfile = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
};

export enum ErrorCode {
  Unauthenticated = 'UNAUTHENTICATED',
  NotRegistered = 'NOT_REGISTERED',
  Unauthorized = 'UNAUTHORIZED',
  AlreadyExist = 'ALREADY_EXIST',
  InvalidArgument = 'INVALID_ARGUMENT',
  NotFound = 'NOT_FOUND',
  Internal = 'INTERNAL',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER',
  Unanswered = 'UNANSWERED',
}

export type Message = Node & {
  id: Scalars['ID'];
  userId: Scalars['ID'];
  userProfile: UserProfile;
  messageRoomID: Scalars['ID'];
  messageRoom: MessageRoom;
  body: Scalars['String'];
  createdAt: Scalars['Date'];
};

export type MessageConnection = {
  pageInfo: PageInfo;
  edges: Array<MessageEdge>;
};

export type MessageEdge = {
  node: Message;
  cursor: Scalars['String'];
};

export type MessageRoom = Node & {
  id: Scalars['ID'];
  userId: Scalars['ID'];
  userProfile: UserProfile;
  userMessageLastReadAt?: Maybe<Scalars['Time']>;
  projectId: Scalars['ID'];
  project: Project;
  latestMessage?: Maybe<Message>;
};

export type Mutation = {
  registerUser: User;
  updateUserProfile: User;
  becomeOwner: Owner;
  createProjectInterest: ProjectInterest;
  createProject: Project;
  saveProjectOverview: Project;
  saveProjectContent: Project;
  postMessage: Message;
  updateMessageLastRead: Scalars['Boolean'];
  adminCreateOwner: Owner;
  adminCreateProject: Project;
  adminSaveProjectOverview: Project;
  adminSaveProjectContent: Project;
  adminPublishProject: Project;
  adminWithdrawPublishedProject: Project;
  adminPostMessage: Message;
};

export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};

export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type MutationCreateProjectInterestArgs = {
  input: CreateProjectInterest;
};

export type MutationSaveProjectOverviewArgs = {
  input: SaveProjectOverviewInput;
};

export type MutationSaveProjectContentArgs = {
  input: SaveProjectContentInput;
};

export type MutationPostMessageArgs = {
  input: PostMessageInput;
};

export type MutationUpdateMessageLastReadArgs = {
  input: UpdateMessageLastReadInput;
};

export type MutationAdminCreateOwnerArgs = {
  userId: Scalars['ID'];
};

export type MutationAdminCreateProjectArgs = {
  ownerId: Scalars['ID'];
};

export type MutationAdminSaveProjectOverviewArgs = {
  input: SaveProjectOverviewInput;
};

export type MutationAdminSaveProjectContentArgs = {
  input: SaveProjectContentInput;
};

export type MutationAdminPublishProjectArgs = {
  projectId: Scalars['ID'];
};

export type MutationAdminWithdrawPublishedProjectArgs = {
  projectId: Scalars['ID'];
};

export type MutationAdminPostMessageArgs = {
  input: PostMessageInput;
};

export type Node = {
  id: Scalars['ID'];
};

export type Owner = Node & {
  id: Scalars['ID'];
  userId: Scalars['ID'];
  userProfile: UserProfile;
  projects: Array<Project>;
};

export type PageInfo = {
  hasNextPage: Scalars['Boolean'];
  HasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type PostMessageInput = {
  messageRoomId: Scalars['ID'];
  body: Scalars['String'];
};

export type Prefecture = Node & {
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  enName: Scalars['String'];
  kanaName: Scalars['String'];
  cities: Array<City>;
};

export type Project = Node & {
  id: Scalars['ID'];
  ownerId: Scalars['ID'];
  owner: Owner;
  cityId?: Maybe<Scalars['ID']>;
  city?: Maybe<City>;
  publishStatus: ProjectPublishStatus;
  title: Scalars['String'];
  description: Scalars['String'];
  mainImgUrl?: Maybe<Scalars['String']>;
  content: Scalars['String'];
};

export type ProjectInterest = Node & {
  id: Scalars['ID'];
  userId: Scalars['ID'];
  userProfile: UserProfile;
  projectId: Scalars['ID'];
  project: Project;
};

export enum ProjectPublishStatus {
  Draft = 'DRAFT',
  UnderReview = 'UNDER_REVIEW',
  Rejected = 'REJECTED',
  Publishable = 'PUBLISHABLE',
  Published = 'PUBLISHED',
  Closed = 'CLOSED',
}

export type Query = {
  node: Node;
  nodes: Array<Node>;
  allPrefectures: Array<Prefecture>;
  currentTempUserProfile: CurrentTempUserProfile;
  viewer: User;
  projectsForHome: Array<Project>;
  messages: MessageConnection;
  adminAllUsers: Array<UserProfile>;
  adminAllOwners: Array<Owner>;
  adminAllProjects: Array<Project>;
  adminAllMessageRooms: Array<MessageRoom>;
  adminMessages: MessageConnection;
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryMessagesArgs = {
  messageRoomId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type QueryAdminMessagesArgs = {
  messageRoomId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type RegisterUserInput = {
  nickname: Scalars['String'];
  avatar?: Maybe<Scalars['Upload']>;
  birthdate: Scalars['Date'];
  gender: Gender;
  cityId: Scalars['ID'];
  skill: Scalars['String'];
};

export enum Role {
  User = 'USER',
  TempUser = 'TEMP_USER',
  Owner = 'OWNER',
  Admin = 'ADMIN',
}

export type SaveProjectContentInput = {
  projectId: Scalars['ID'];
  content: Scalars['String'];
};

export type SaveProjectOverviewInput = {
  projectId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  mainImg?: Maybe<Scalars['Upload']>;
  cityId: Scalars['ID'];
};

export type UpdateMessageLastReadInput = {
  messageRoomId: Scalars['ID'];
  readAt: Scalars['Time'];
};

export type UpdateUserProfileInput = {
  nickname: Scalars['String'];
  avatar?: Maybe<Scalars['Upload']>;
  birthdate: Scalars['Date'];
  gender: Gender;
  cityId: Scalars['ID'];
  skill: Scalars['String'];
};

export type User = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  birthdate: Scalars['Date'];
  gender: Gender;
  cityId: Scalars['ID'];
  city: City;
  skill: Scalars['String'];
  owner?: Maybe<Owner>;
  projectInterest?: Maybe<ProjectInterest>;
  interestedProjects: Array<Project>;
  messageRooms: Array<MessageRoom>;
};

export type UserProjectInterestArgs = {
  projectId: Scalars['ID'];
};

export type UserProfile = Node & {
  id: Scalars['ID'];
  nickname: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  owner?: Maybe<Owner>;
};

export type HeaderComponentViewerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HeaderComponentViewerQuery = {
  viewer: Pick<User, 'id' | 'nickname' | 'avatarUrl'> & {
    owner?: Maybe<Pick<Owner, 'id'>>;
  };
};

export type MessageListMessageFragment = Pick<
  Message,
  'id' | 'userId' | 'body' | 'createdAt'
> & { userProfile: Pick<UserProfile, 'nickname' | 'avatarUrl'> };

export type ProjectCardProjectFragment = Pick<
  Project,
  'id' | 'title' | 'mainImgUrl'
> & {
  city?: Maybe<Pick<City, 'name'> & { prefecture: Pick<Prefecture, 'name'> }>;
  owner: Pick<Owner, 'id'> & {
    userProfile: Pick<UserProfile, 'nickname' | 'avatarUrl'>;
  };
};

export type HomePageProjectsForHomeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HomePageProjectsForHomeQuery = {
  projectsForHome: Array<ProjectCardProjectFragment>;
};

export type IndexPageProjectsForHomeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IndexPageProjectsForHomeQuery = {
  projectsForHome: Array<ProjectCardProjectFragment>;
};

export type ProjectDetailPageProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectDetailPageProjectQuery = {
  node: Pick<
    Project,
    'id' | 'title' | 'description' | 'mainImgUrl' | 'content'
  > & {
    owner: { userProfile: Pick<UserProfile, 'id' | 'nickname' | 'avatarUrl'> };
    city?: Maybe<Pick<City, 'name'> & { prefecture: Pick<Prefecture, 'name'> }>;
  };
};

export type ProjectDetailPageViewerQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type ProjectDetailPageViewerQuery = {
  viewer: Pick<User, 'id'> & {
    projectInterest?: Maybe<Pick<ProjectInterest, 'id'>>;
  };
};

export type ProjectDetailPageCreateProjectInterestMutationVariables = Exact<{
  input: CreateProjectInterest;
}>;

export type ProjectDetailPageCreateProjectInterestMutation = {
  createProjectInterest: Pick<ProjectInterest, 'id'>;
};

export type ProjectEditPageProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProjectEditPageProjectQuery = {
  node: Pick<Project, 'id' | 'title' | 'description' | 'content' | 'cityId'> & {
    city?: Maybe<Pick<City, 'prefectureId'>>;
  };
};

export type ProjectEditPageSaveProjectOverviewMutationVariables = Exact<{
  input: SaveProjectOverviewInput;
}>;

export type ProjectEditPageSaveProjectOverviewMutation = {
  saveProjectOverview: Pick<
    Project,
    'id' | 'title' | 'description' | 'content' | 'cityId'
  >;
};

export type ProjectEditPageSaveProjectContentMutationVariables = Exact<{
  input: SaveProjectContentInput;
}>;

export type ProjectEditPageSaveProjectContentMutation = {
  saveProjectContent: Pick<Project, 'id' | 'title' | 'description' | 'content'>;
};

export type UserMessageRoomDetailPageMessageRoomQueryVariables = Exact<{
  messageRoomId: Scalars['ID'];
}>;

export type UserMessageRoomDetailPageMessageRoomQuery = {
  node: Pick<MessageRoom, 'userId'> & {
    project: Pick<Project, 'id' | 'title' | 'mainImgUrl'>;
  };
};

export type UserMessageRoomDetailPageMessagesQueryVariables = Exact<{
  messageRoomId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;

export type UserMessageRoomDetailPageMessagesQuery = {
  messages: {
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>;
    edges: Array<{ node: MessageListMessageFragment }>;
  };
};

export type UserMessageRoomDetailPagePostMessageMutationVariables = Exact<{
  input: PostMessageInput;
}>;

export type UserMessageRoomDetailPagePostMessageMutation = {
  postMessage: MessageListMessageFragment;
};

export type UserMessageRoomDetailPageUpdateMessageLastReadMutationVariables =
  Exact<{
    input: UpdateMessageLastReadInput;
  }>;

export type UserMessageRoomDetailPageUpdateMessageLastReadMutation = Pick<
  Mutation,
  'updateMessageLastRead'
>;

export type UserMessageRoomsPageMessageRoomsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserMessageRoomsPageMessageRoomsQuery = {
  viewer: {
    messageRooms: Array<
      Pick<MessageRoom, 'id' | 'userMessageLastReadAt'> & {
        project: {
          owner: { userProfile: Pick<UserProfile, 'nickname' | 'avatarUrl'> };
        };
        latestMessage?: Maybe<Pick<Message, 'body' | 'createdAt'>>;
      }
    >;
  };
};

export type OwnerRegistrationPageBecomeOwnerMutationVariables = Exact<{
  [key: string]: never;
}>;

export type OwnerRegistrationPageBecomeOwnerMutation = {
  becomeOwner: Pick<Owner, 'id'>;
};

export type UserProfileEditPageViewerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserProfileEditPageViewerQuery = {
  viewer: Pick<
    User,
    'id' | 'nickname' | 'avatarUrl' | 'birthdate' | 'gender' | 'skill'
  > & {
    city: Pick<City, 'id' | 'name'> & {
      prefecture: Pick<Prefecture, 'id' | 'name'>;
    };
  };
};

export type UserProfileEditPageAllPrefecturesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserProfileEditPageAllPrefecturesQuery = {
  allPrefectures: Array<
    Pick<Prefecture, 'id' | 'name'> & {
      cities: Array<Pick<City, 'id' | 'name'>>;
    }
  >;
};

export type UserProfileEditPageUpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;

export type UserProfileEditPageUpdateUserProfileMutation = {
  updateUserProfile: Pick<
    User,
    'id' | 'nickname' | 'avatarUrl' | 'birthdate' | 'gender' | 'skill'
  > & {
    city: Pick<City, 'id' | 'name'> & {
      prefecture: Pick<Prefecture, 'id' | 'name'>;
    };
  };
};

export type UserProfilePageViewerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserProfilePageViewerQuery = {
  viewer: Pick<
    User,
    'id' | 'nickname' | 'avatarUrl' | 'birthdate' | 'gender' | 'skill'
  > & {
    city: Pick<City, 'name'> & { prefecture: Pick<Prefecture, 'name'> };
    interestedProjects: Array<ProjectCardProjectFragment>;
  };
};

export type UserProjectsPageViewerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserProjectsPageViewerQuery = {
  viewer: {
    owner?: Maybe<
      Pick<Owner, 'id'> & { projects: Array<Pick<Project, 'id' | 'title'>> }
    >;
  };
};

export type UserProjectsPageCreateProjectMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UserProjectsPageCreateProjectMutation = {
  createProject: Pick<Project, 'id'>;
};

export type RegistrationPageCurrentTempUserProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RegistrationPageCurrentTempUserProfileQuery = {
  currentTempUserProfile: Pick<
    CurrentTempUserProfile,
    'id' | 'nickname' | 'avatarUrl'
  >;
};

export type RegistrationPageAllPrefecturesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RegistrationPageAllPrefecturesQuery = {
  allPrefectures: Array<
    Pick<Prefecture, 'id' | 'name'> & {
      cities: Array<Pick<City, 'id' | 'name'>>;
    }
  >;
};

export type RegistrationPageRegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;

export type RegistrationPageRegisterUserMutation = {
  registerUser: Pick<User, 'id'>;
};

export const MessageListMessageFragmentDoc = gql`
  fragment messageListMessage on Message {
    ... on Message {
      id
      userId
      userProfile {
        nickname
        avatarUrl
      }
      body
      createdAt
    }
  }
`;
export const ProjectCardProjectFragmentDoc = gql`
  fragment ProjectCardProject on Project {
    id
    title
    mainImgUrl
    city {
      name
      prefecture {
        name
      }
    }
    owner {
      id
      userProfile {
        nickname
        avatarUrl
      }
    }
  }
`;
export const HeaderComponentViewerDocument = gql`
  query headerComponentViewer {
    viewer {
      id
      nickname
      avatarUrl
      owner {
        id
      }
    }
  }
`;

/**
 * __useHeaderComponentViewerQuery__
 *
 * To run a query within a React component, call `useHeaderComponentViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderComponentViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderComponentViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeaderComponentViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HeaderComponentViewerQuery,
    HeaderComponentViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HeaderComponentViewerQuery,
    HeaderComponentViewerQueryVariables
  >(HeaderComponentViewerDocument, options);
}
export function useHeaderComponentViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HeaderComponentViewerQuery,
    HeaderComponentViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HeaderComponentViewerQuery,
    HeaderComponentViewerQueryVariables
  >(HeaderComponentViewerDocument, options);
}
export type HeaderComponentViewerQueryHookResult = ReturnType<
  typeof useHeaderComponentViewerQuery
>;
export type HeaderComponentViewerLazyQueryHookResult = ReturnType<
  typeof useHeaderComponentViewerLazyQuery
>;
export type HeaderComponentViewerQueryResult = Apollo.QueryResult<
  HeaderComponentViewerQuery,
  HeaderComponentViewerQueryVariables
>;
export const HomePageProjectsForHomeDocument = gql`
  query homePageProjectsForHome {
    projectsForHome {
      ...ProjectCardProject
    }
  }
  ${ProjectCardProjectFragmentDoc}
`;

/**
 * __useHomePageProjectsForHomeQuery__
 *
 * To run a query within a React component, call `useHomePageProjectsForHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageProjectsForHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageProjectsForHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageProjectsForHomeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomePageProjectsForHomeQuery,
    HomePageProjectsForHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HomePageProjectsForHomeQuery,
    HomePageProjectsForHomeQueryVariables
  >(HomePageProjectsForHomeDocument, options);
}
export function useHomePageProjectsForHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomePageProjectsForHomeQuery,
    HomePageProjectsForHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomePageProjectsForHomeQuery,
    HomePageProjectsForHomeQueryVariables
  >(HomePageProjectsForHomeDocument, options);
}
export type HomePageProjectsForHomeQueryHookResult = ReturnType<
  typeof useHomePageProjectsForHomeQuery
>;
export type HomePageProjectsForHomeLazyQueryHookResult = ReturnType<
  typeof useHomePageProjectsForHomeLazyQuery
>;
export type HomePageProjectsForHomeQueryResult = Apollo.QueryResult<
  HomePageProjectsForHomeQuery,
  HomePageProjectsForHomeQueryVariables
>;
export const IndexPageProjectsForHomeDocument = gql`
  query indexPageProjectsForHome {
    projectsForHome {
      ...ProjectCardProject
    }
  }
  ${ProjectCardProjectFragmentDoc}
`;

/**
 * __useIndexPageProjectsForHomeQuery__
 *
 * To run a query within a React component, call `useIndexPageProjectsForHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexPageProjectsForHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexPageProjectsForHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndexPageProjectsForHomeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndexPageProjectsForHomeQuery,
    IndexPageProjectsForHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndexPageProjectsForHomeQuery,
    IndexPageProjectsForHomeQueryVariables
  >(IndexPageProjectsForHomeDocument, options);
}
export function useIndexPageProjectsForHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexPageProjectsForHomeQuery,
    IndexPageProjectsForHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndexPageProjectsForHomeQuery,
    IndexPageProjectsForHomeQueryVariables
  >(IndexPageProjectsForHomeDocument, options);
}
export type IndexPageProjectsForHomeQueryHookResult = ReturnType<
  typeof useIndexPageProjectsForHomeQuery
>;
export type IndexPageProjectsForHomeLazyQueryHookResult = ReturnType<
  typeof useIndexPageProjectsForHomeLazyQuery
>;
export type IndexPageProjectsForHomeQueryResult = Apollo.QueryResult<
  IndexPageProjectsForHomeQuery,
  IndexPageProjectsForHomeQueryVariables
>;
export const ProjectDetailPageProjectDocument = gql`
  query projectDetailPageProject($id: ID!) {
    node(id: $id) {
      ... on Project {
        id
        title
        description
        mainImgUrl
        content
        owner {
          userProfile {
            id
            nickname
            avatarUrl
          }
        }
        city {
          name
          prefecture {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useProjectDetailPageProjectQuery__
 *
 * To run a query within a React component, call `useProjectDetailPageProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailPageProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDetailPageProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectDetailPageProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectDetailPageProjectQuery,
    ProjectDetailPageProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectDetailPageProjectQuery,
    ProjectDetailPageProjectQueryVariables
  >(ProjectDetailPageProjectDocument, options);
}
export function useProjectDetailPageProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectDetailPageProjectQuery,
    ProjectDetailPageProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectDetailPageProjectQuery,
    ProjectDetailPageProjectQueryVariables
  >(ProjectDetailPageProjectDocument, options);
}
export type ProjectDetailPageProjectQueryHookResult = ReturnType<
  typeof useProjectDetailPageProjectQuery
>;
export type ProjectDetailPageProjectLazyQueryHookResult = ReturnType<
  typeof useProjectDetailPageProjectLazyQuery
>;
export type ProjectDetailPageProjectQueryResult = Apollo.QueryResult<
  ProjectDetailPageProjectQuery,
  ProjectDetailPageProjectQueryVariables
>;
export const ProjectDetailPageViewerDocument = gql`
  query projectDetailPageViewer($projectId: ID!) {
    viewer {
      id
      projectInterest(projectId: $projectId) {
        id
      }
    }
  }
`;

/**
 * __useProjectDetailPageViewerQuery__
 *
 * To run a query within a React component, call `useProjectDetailPageViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailPageViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDetailPageViewerQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectDetailPageViewerQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectDetailPageViewerQuery,
    ProjectDetailPageViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectDetailPageViewerQuery,
    ProjectDetailPageViewerQueryVariables
  >(ProjectDetailPageViewerDocument, options);
}
export function useProjectDetailPageViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectDetailPageViewerQuery,
    ProjectDetailPageViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectDetailPageViewerQuery,
    ProjectDetailPageViewerQueryVariables
  >(ProjectDetailPageViewerDocument, options);
}
export type ProjectDetailPageViewerQueryHookResult = ReturnType<
  typeof useProjectDetailPageViewerQuery
>;
export type ProjectDetailPageViewerLazyQueryHookResult = ReturnType<
  typeof useProjectDetailPageViewerLazyQuery
>;
export type ProjectDetailPageViewerQueryResult = Apollo.QueryResult<
  ProjectDetailPageViewerQuery,
  ProjectDetailPageViewerQueryVariables
>;
export const ProjectDetailPageCreateProjectInterestDocument = gql`
  mutation projectDetailPageCreateProjectInterest(
    $input: CreateProjectInterest!
  ) {
    createProjectInterest(input: $input) {
      id
    }
  }
`;
export type ProjectDetailPageCreateProjectInterestMutationFn =
  Apollo.MutationFunction<
    ProjectDetailPageCreateProjectInterestMutation,
    ProjectDetailPageCreateProjectInterestMutationVariables
  >;

/**
 * __useProjectDetailPageCreateProjectInterestMutation__
 *
 * To run a mutation, you first call `useProjectDetailPageCreateProjectInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailPageCreateProjectInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectDetailPageCreateProjectInterestMutation, { data, loading, error }] = useProjectDetailPageCreateProjectInterestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectDetailPageCreateProjectInterestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProjectDetailPageCreateProjectInterestMutation,
    ProjectDetailPageCreateProjectInterestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProjectDetailPageCreateProjectInterestMutation,
    ProjectDetailPageCreateProjectInterestMutationVariables
  >(ProjectDetailPageCreateProjectInterestDocument, options);
}
export type ProjectDetailPageCreateProjectInterestMutationHookResult =
  ReturnType<typeof useProjectDetailPageCreateProjectInterestMutation>;
export type ProjectDetailPageCreateProjectInterestMutationResult =
  Apollo.MutationResult<ProjectDetailPageCreateProjectInterestMutation>;
export type ProjectDetailPageCreateProjectInterestMutationOptions =
  Apollo.BaseMutationOptions<
    ProjectDetailPageCreateProjectInterestMutation,
    ProjectDetailPageCreateProjectInterestMutationVariables
  >;
export const ProjectEditPageProjectDocument = gql`
  query projectEditPageProject($id: ID!) {
    node(id: $id) {
      ... on Project {
        id
        title
        description
        content
        cityId
        city {
          prefectureId
        }
      }
    }
  }
`;

/**
 * __useProjectEditPageProjectQuery__
 *
 * To run a query within a React component, call `useProjectEditPageProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectEditPageProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectEditPageProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectEditPageProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectEditPageProjectQuery,
    ProjectEditPageProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectEditPageProjectQuery,
    ProjectEditPageProjectQueryVariables
  >(ProjectEditPageProjectDocument, options);
}
export function useProjectEditPageProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectEditPageProjectQuery,
    ProjectEditPageProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectEditPageProjectQuery,
    ProjectEditPageProjectQueryVariables
  >(ProjectEditPageProjectDocument, options);
}
export type ProjectEditPageProjectQueryHookResult = ReturnType<
  typeof useProjectEditPageProjectQuery
>;
export type ProjectEditPageProjectLazyQueryHookResult = ReturnType<
  typeof useProjectEditPageProjectLazyQuery
>;
export type ProjectEditPageProjectQueryResult = Apollo.QueryResult<
  ProjectEditPageProjectQuery,
  ProjectEditPageProjectQueryVariables
>;
export const ProjectEditPageSaveProjectOverviewDocument = gql`
  mutation projectEditPageSaveProjectOverview(
    $input: SaveProjectOverviewInput!
  ) {
    saveProjectOverview(input: $input) {
      id
      title
      description
      content
      cityId
    }
  }
`;
export type ProjectEditPageSaveProjectOverviewMutationFn =
  Apollo.MutationFunction<
    ProjectEditPageSaveProjectOverviewMutation,
    ProjectEditPageSaveProjectOverviewMutationVariables
  >;

/**
 * __useProjectEditPageSaveProjectOverviewMutation__
 *
 * To run a mutation, you first call `useProjectEditPageSaveProjectOverviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectEditPageSaveProjectOverviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectEditPageSaveProjectOverviewMutation, { data, loading, error }] = useProjectEditPageSaveProjectOverviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectEditPageSaveProjectOverviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProjectEditPageSaveProjectOverviewMutation,
    ProjectEditPageSaveProjectOverviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProjectEditPageSaveProjectOverviewMutation,
    ProjectEditPageSaveProjectOverviewMutationVariables
  >(ProjectEditPageSaveProjectOverviewDocument, options);
}
export type ProjectEditPageSaveProjectOverviewMutationHookResult = ReturnType<
  typeof useProjectEditPageSaveProjectOverviewMutation
>;
export type ProjectEditPageSaveProjectOverviewMutationResult =
  Apollo.MutationResult<ProjectEditPageSaveProjectOverviewMutation>;
export type ProjectEditPageSaveProjectOverviewMutationOptions =
  Apollo.BaseMutationOptions<
    ProjectEditPageSaveProjectOverviewMutation,
    ProjectEditPageSaveProjectOverviewMutationVariables
  >;
export const ProjectEditPageSaveProjectContentDocument = gql`
  mutation projectEditPageSaveProjectContent($input: SaveProjectContentInput!) {
    saveProjectContent(input: $input) {
      id
      title
      description
      content
    }
  }
`;
export type ProjectEditPageSaveProjectContentMutationFn =
  Apollo.MutationFunction<
    ProjectEditPageSaveProjectContentMutation,
    ProjectEditPageSaveProjectContentMutationVariables
  >;

/**
 * __useProjectEditPageSaveProjectContentMutation__
 *
 * To run a mutation, you first call `useProjectEditPageSaveProjectContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectEditPageSaveProjectContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectEditPageSaveProjectContentMutation, { data, loading, error }] = useProjectEditPageSaveProjectContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectEditPageSaveProjectContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProjectEditPageSaveProjectContentMutation,
    ProjectEditPageSaveProjectContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProjectEditPageSaveProjectContentMutation,
    ProjectEditPageSaveProjectContentMutationVariables
  >(ProjectEditPageSaveProjectContentDocument, options);
}
export type ProjectEditPageSaveProjectContentMutationHookResult = ReturnType<
  typeof useProjectEditPageSaveProjectContentMutation
>;
export type ProjectEditPageSaveProjectContentMutationResult =
  Apollo.MutationResult<ProjectEditPageSaveProjectContentMutation>;
export type ProjectEditPageSaveProjectContentMutationOptions =
  Apollo.BaseMutationOptions<
    ProjectEditPageSaveProjectContentMutation,
    ProjectEditPageSaveProjectContentMutationVariables
  >;
export const UserMessageRoomDetailPageMessageRoomDocument = gql`
  query userMessageRoomDetailPageMessageRoom($messageRoomId: ID!) {
    node(id: $messageRoomId) {
      ... on MessageRoom {
        userId
        project {
          id
          title
          mainImgUrl
        }
      }
    }
  }
`;

/**
 * __useUserMessageRoomDetailPageMessageRoomQuery__
 *
 * To run a query within a React component, call `useUserMessageRoomDetailPageMessageRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMessageRoomDetailPageMessageRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMessageRoomDetailPageMessageRoomQuery({
 *   variables: {
 *      messageRoomId: // value for 'messageRoomId'
 *   },
 * });
 */
export function useUserMessageRoomDetailPageMessageRoomQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserMessageRoomDetailPageMessageRoomQuery,
    UserMessageRoomDetailPageMessageRoomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserMessageRoomDetailPageMessageRoomQuery,
    UserMessageRoomDetailPageMessageRoomQueryVariables
  >(UserMessageRoomDetailPageMessageRoomDocument, options);
}
export function useUserMessageRoomDetailPageMessageRoomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserMessageRoomDetailPageMessageRoomQuery,
    UserMessageRoomDetailPageMessageRoomQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserMessageRoomDetailPageMessageRoomQuery,
    UserMessageRoomDetailPageMessageRoomQueryVariables
  >(UserMessageRoomDetailPageMessageRoomDocument, options);
}
export type UserMessageRoomDetailPageMessageRoomQueryHookResult = ReturnType<
  typeof useUserMessageRoomDetailPageMessageRoomQuery
>;
export type UserMessageRoomDetailPageMessageRoomLazyQueryHookResult =
  ReturnType<typeof useUserMessageRoomDetailPageMessageRoomLazyQuery>;
export type UserMessageRoomDetailPageMessageRoomQueryResult =
  Apollo.QueryResult<
    UserMessageRoomDetailPageMessageRoomQuery,
    UserMessageRoomDetailPageMessageRoomQueryVariables
  >;
export const UserMessageRoomDetailPageMessagesDocument = gql`
  query userMessageRoomDetailPageMessages(
    $messageRoomId: ID!
    $after: String
    $first: Int
  ) {
    messages(messageRoomId: $messageRoomId, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ... on Message {
            ...messageListMessage
          }
        }
      }
    }
  }
  ${MessageListMessageFragmentDoc}
`;

/**
 * __useUserMessageRoomDetailPageMessagesQuery__
 *
 * To run a query within a React component, call `useUserMessageRoomDetailPageMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMessageRoomDetailPageMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMessageRoomDetailPageMessagesQuery({
 *   variables: {
 *      messageRoomId: // value for 'messageRoomId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserMessageRoomDetailPageMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserMessageRoomDetailPageMessagesQuery,
    UserMessageRoomDetailPageMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserMessageRoomDetailPageMessagesQuery,
    UserMessageRoomDetailPageMessagesQueryVariables
  >(UserMessageRoomDetailPageMessagesDocument, options);
}
export function useUserMessageRoomDetailPageMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserMessageRoomDetailPageMessagesQuery,
    UserMessageRoomDetailPageMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserMessageRoomDetailPageMessagesQuery,
    UserMessageRoomDetailPageMessagesQueryVariables
  >(UserMessageRoomDetailPageMessagesDocument, options);
}
export type UserMessageRoomDetailPageMessagesQueryHookResult = ReturnType<
  typeof useUserMessageRoomDetailPageMessagesQuery
>;
export type UserMessageRoomDetailPageMessagesLazyQueryHookResult = ReturnType<
  typeof useUserMessageRoomDetailPageMessagesLazyQuery
>;
export type UserMessageRoomDetailPageMessagesQueryResult = Apollo.QueryResult<
  UserMessageRoomDetailPageMessagesQuery,
  UserMessageRoomDetailPageMessagesQueryVariables
>;
export const UserMessageRoomDetailPagePostMessageDocument = gql`
  mutation userMessageRoomDetailPagePostMessage($input: PostMessageInput!) {
    postMessage(input: $input) {
      ... on Message {
        ...messageListMessage
      }
    }
  }
  ${MessageListMessageFragmentDoc}
`;
export type UserMessageRoomDetailPagePostMessageMutationFn =
  Apollo.MutationFunction<
    UserMessageRoomDetailPagePostMessageMutation,
    UserMessageRoomDetailPagePostMessageMutationVariables
  >;

/**
 * __useUserMessageRoomDetailPagePostMessageMutation__
 *
 * To run a mutation, you first call `useUserMessageRoomDetailPagePostMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserMessageRoomDetailPagePostMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userMessageRoomDetailPagePostMessageMutation, { data, loading, error }] = useUserMessageRoomDetailPagePostMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserMessageRoomDetailPagePostMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserMessageRoomDetailPagePostMessageMutation,
    UserMessageRoomDetailPagePostMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserMessageRoomDetailPagePostMessageMutation,
    UserMessageRoomDetailPagePostMessageMutationVariables
  >(UserMessageRoomDetailPagePostMessageDocument, options);
}
export type UserMessageRoomDetailPagePostMessageMutationHookResult = ReturnType<
  typeof useUserMessageRoomDetailPagePostMessageMutation
>;
export type UserMessageRoomDetailPagePostMessageMutationResult =
  Apollo.MutationResult<UserMessageRoomDetailPagePostMessageMutation>;
export type UserMessageRoomDetailPagePostMessageMutationOptions =
  Apollo.BaseMutationOptions<
    UserMessageRoomDetailPagePostMessageMutation,
    UserMessageRoomDetailPagePostMessageMutationVariables
  >;
export const UserMessageRoomDetailPageUpdateMessageLastReadDocument = gql`
  mutation userMessageRoomDetailPageUpdateMessageLastRead(
    $input: UpdateMessageLastReadInput!
  ) {
    updateMessageLastRead(input: $input)
  }
`;
export type UserMessageRoomDetailPageUpdateMessageLastReadMutationFn =
  Apollo.MutationFunction<
    UserMessageRoomDetailPageUpdateMessageLastReadMutation,
    UserMessageRoomDetailPageUpdateMessageLastReadMutationVariables
  >;

/**
 * __useUserMessageRoomDetailPageUpdateMessageLastReadMutation__
 *
 * To run a mutation, you first call `useUserMessageRoomDetailPageUpdateMessageLastReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserMessageRoomDetailPageUpdateMessageLastReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userMessageRoomDetailPageUpdateMessageLastReadMutation, { data, loading, error }] = useUserMessageRoomDetailPageUpdateMessageLastReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserMessageRoomDetailPageUpdateMessageLastReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserMessageRoomDetailPageUpdateMessageLastReadMutation,
    UserMessageRoomDetailPageUpdateMessageLastReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserMessageRoomDetailPageUpdateMessageLastReadMutation,
    UserMessageRoomDetailPageUpdateMessageLastReadMutationVariables
  >(UserMessageRoomDetailPageUpdateMessageLastReadDocument, options);
}
export type UserMessageRoomDetailPageUpdateMessageLastReadMutationHookResult =
  ReturnType<typeof useUserMessageRoomDetailPageUpdateMessageLastReadMutation>;
export type UserMessageRoomDetailPageUpdateMessageLastReadMutationResult =
  Apollo.MutationResult<UserMessageRoomDetailPageUpdateMessageLastReadMutation>;
export type UserMessageRoomDetailPageUpdateMessageLastReadMutationOptions =
  Apollo.BaseMutationOptions<
    UserMessageRoomDetailPageUpdateMessageLastReadMutation,
    UserMessageRoomDetailPageUpdateMessageLastReadMutationVariables
  >;
export const UserMessageRoomsPageMessageRoomsDocument = gql`
  query userMessageRoomsPageMessageRooms {
    viewer {
      messageRooms {
        id
        userMessageLastReadAt
        project {
          owner {
            userProfile {
              nickname
              avatarUrl
            }
          }
        }
        latestMessage {
          body
          createdAt
        }
      }
    }
  }
`;

/**
 * __useUserMessageRoomsPageMessageRoomsQuery__
 *
 * To run a query within a React component, call `useUserMessageRoomsPageMessageRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMessageRoomsPageMessageRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMessageRoomsPageMessageRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMessageRoomsPageMessageRoomsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserMessageRoomsPageMessageRoomsQuery,
    UserMessageRoomsPageMessageRoomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserMessageRoomsPageMessageRoomsQuery,
    UserMessageRoomsPageMessageRoomsQueryVariables
  >(UserMessageRoomsPageMessageRoomsDocument, options);
}
export function useUserMessageRoomsPageMessageRoomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserMessageRoomsPageMessageRoomsQuery,
    UserMessageRoomsPageMessageRoomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserMessageRoomsPageMessageRoomsQuery,
    UserMessageRoomsPageMessageRoomsQueryVariables
  >(UserMessageRoomsPageMessageRoomsDocument, options);
}
export type UserMessageRoomsPageMessageRoomsQueryHookResult = ReturnType<
  typeof useUserMessageRoomsPageMessageRoomsQuery
>;
export type UserMessageRoomsPageMessageRoomsLazyQueryHookResult = ReturnType<
  typeof useUserMessageRoomsPageMessageRoomsLazyQuery
>;
export type UserMessageRoomsPageMessageRoomsQueryResult = Apollo.QueryResult<
  UserMessageRoomsPageMessageRoomsQuery,
  UserMessageRoomsPageMessageRoomsQueryVariables
>;
export const OwnerRegistrationPageBecomeOwnerDocument = gql`
  mutation ownerRegistrationPageBecomeOwner {
    becomeOwner {
      id
    }
  }
`;
export type OwnerRegistrationPageBecomeOwnerMutationFn =
  Apollo.MutationFunction<
    OwnerRegistrationPageBecomeOwnerMutation,
    OwnerRegistrationPageBecomeOwnerMutationVariables
  >;

/**
 * __useOwnerRegistrationPageBecomeOwnerMutation__
 *
 * To run a mutation, you first call `useOwnerRegistrationPageBecomeOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOwnerRegistrationPageBecomeOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ownerRegistrationPageBecomeOwnerMutation, { data, loading, error }] = useOwnerRegistrationPageBecomeOwnerMutation({
 *   variables: {
 *   },
 * });
 */
export function useOwnerRegistrationPageBecomeOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OwnerRegistrationPageBecomeOwnerMutation,
    OwnerRegistrationPageBecomeOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OwnerRegistrationPageBecomeOwnerMutation,
    OwnerRegistrationPageBecomeOwnerMutationVariables
  >(OwnerRegistrationPageBecomeOwnerDocument, options);
}
export type OwnerRegistrationPageBecomeOwnerMutationHookResult = ReturnType<
  typeof useOwnerRegistrationPageBecomeOwnerMutation
>;
export type OwnerRegistrationPageBecomeOwnerMutationResult =
  Apollo.MutationResult<OwnerRegistrationPageBecomeOwnerMutation>;
export type OwnerRegistrationPageBecomeOwnerMutationOptions =
  Apollo.BaseMutationOptions<
    OwnerRegistrationPageBecomeOwnerMutation,
    OwnerRegistrationPageBecomeOwnerMutationVariables
  >;
export const UserProfileEditPageViewerDocument = gql`
  query userProfileEditPageViewer {
    viewer {
      id
      nickname
      avatarUrl
      birthdate
      gender
      skill
      city {
        id
        name
        prefecture {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useUserProfileEditPageViewerQuery__
 *
 * To run a query within a React component, call `useUserProfileEditPageViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileEditPageViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileEditPageViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileEditPageViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserProfileEditPageViewerQuery,
    UserProfileEditPageViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserProfileEditPageViewerQuery,
    UserProfileEditPageViewerQueryVariables
  >(UserProfileEditPageViewerDocument, options);
}
export function useUserProfileEditPageViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileEditPageViewerQuery,
    UserProfileEditPageViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserProfileEditPageViewerQuery,
    UserProfileEditPageViewerQueryVariables
  >(UserProfileEditPageViewerDocument, options);
}
export type UserProfileEditPageViewerQueryHookResult = ReturnType<
  typeof useUserProfileEditPageViewerQuery
>;
export type UserProfileEditPageViewerLazyQueryHookResult = ReturnType<
  typeof useUserProfileEditPageViewerLazyQuery
>;
export type UserProfileEditPageViewerQueryResult = Apollo.QueryResult<
  UserProfileEditPageViewerQuery,
  UserProfileEditPageViewerQueryVariables
>;
export const UserProfileEditPageAllPrefecturesDocument = gql`
  query userProfileEditPageAllPrefectures {
    allPrefectures {
      id
      name
      cities {
        id
        name
      }
    }
  }
`;

/**
 * __useUserProfileEditPageAllPrefecturesQuery__
 *
 * To run a query within a React component, call `useUserProfileEditPageAllPrefecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileEditPageAllPrefecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileEditPageAllPrefecturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileEditPageAllPrefecturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserProfileEditPageAllPrefecturesQuery,
    UserProfileEditPageAllPrefecturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserProfileEditPageAllPrefecturesQuery,
    UserProfileEditPageAllPrefecturesQueryVariables
  >(UserProfileEditPageAllPrefecturesDocument, options);
}
export function useUserProfileEditPageAllPrefecturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileEditPageAllPrefecturesQuery,
    UserProfileEditPageAllPrefecturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserProfileEditPageAllPrefecturesQuery,
    UserProfileEditPageAllPrefecturesQueryVariables
  >(UserProfileEditPageAllPrefecturesDocument, options);
}
export type UserProfileEditPageAllPrefecturesQueryHookResult = ReturnType<
  typeof useUserProfileEditPageAllPrefecturesQuery
>;
export type UserProfileEditPageAllPrefecturesLazyQueryHookResult = ReturnType<
  typeof useUserProfileEditPageAllPrefecturesLazyQuery
>;
export type UserProfileEditPageAllPrefecturesQueryResult = Apollo.QueryResult<
  UserProfileEditPageAllPrefecturesQuery,
  UserProfileEditPageAllPrefecturesQueryVariables
>;
export const UserProfileEditPageUpdateUserProfileDocument = gql`
  mutation userProfileEditPageUpdateUserProfile(
    $input: UpdateUserProfileInput!
  ) {
    updateUserProfile(input: $input) {
      id
      nickname
      avatarUrl
      birthdate
      gender
      skill
      city {
        id
        name
        prefecture {
          id
          name
        }
      }
    }
  }
`;
export type UserProfileEditPageUpdateUserProfileMutationFn =
  Apollo.MutationFunction<
    UserProfileEditPageUpdateUserProfileMutation,
    UserProfileEditPageUpdateUserProfileMutationVariables
  >;

/**
 * __useUserProfileEditPageUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUserProfileEditPageUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileEditPageUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileEditPageUpdateUserProfileMutation, { data, loading, error }] = useUserProfileEditPageUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserProfileEditPageUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserProfileEditPageUpdateUserProfileMutation,
    UserProfileEditPageUpdateUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserProfileEditPageUpdateUserProfileMutation,
    UserProfileEditPageUpdateUserProfileMutationVariables
  >(UserProfileEditPageUpdateUserProfileDocument, options);
}
export type UserProfileEditPageUpdateUserProfileMutationHookResult = ReturnType<
  typeof useUserProfileEditPageUpdateUserProfileMutation
>;
export type UserProfileEditPageUpdateUserProfileMutationResult =
  Apollo.MutationResult<UserProfileEditPageUpdateUserProfileMutation>;
export type UserProfileEditPageUpdateUserProfileMutationOptions =
  Apollo.BaseMutationOptions<
    UserProfileEditPageUpdateUserProfileMutation,
    UserProfileEditPageUpdateUserProfileMutationVariables
  >;
export const UserProfilePageViewerDocument = gql`
  query userProfilePageViewer {
    viewer {
      id
      nickname
      avatarUrl
      birthdate
      gender
      skill
      city {
        name
        prefecture {
          name
        }
      }
      interestedProjects {
        ... on Project {
          ...ProjectCardProject
        }
      }
    }
  }
  ${ProjectCardProjectFragmentDoc}
`;

/**
 * __useUserProfilePageViewerQuery__
 *
 * To run a query within a React component, call `useUserProfilePageViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfilePageViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfilePageViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfilePageViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserProfilePageViewerQuery,
    UserProfilePageViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserProfilePageViewerQuery,
    UserProfilePageViewerQueryVariables
  >(UserProfilePageViewerDocument, options);
}
export function useUserProfilePageViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfilePageViewerQuery,
    UserProfilePageViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserProfilePageViewerQuery,
    UserProfilePageViewerQueryVariables
  >(UserProfilePageViewerDocument, options);
}
export type UserProfilePageViewerQueryHookResult = ReturnType<
  typeof useUserProfilePageViewerQuery
>;
export type UserProfilePageViewerLazyQueryHookResult = ReturnType<
  typeof useUserProfilePageViewerLazyQuery
>;
export type UserProfilePageViewerQueryResult = Apollo.QueryResult<
  UserProfilePageViewerQuery,
  UserProfilePageViewerQueryVariables
>;
export const UserProjectsPageViewerDocument = gql`
  query userProjectsPageViewer {
    viewer {
      owner {
        id
        projects {
          id
          title
        }
      }
    }
  }
`;

/**
 * __useUserProjectsPageViewerQuery__
 *
 * To run a query within a React component, call `useUserProjectsPageViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProjectsPageViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProjectsPageViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProjectsPageViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserProjectsPageViewerQuery,
    UserProjectsPageViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserProjectsPageViewerQuery,
    UserProjectsPageViewerQueryVariables
  >(UserProjectsPageViewerDocument, options);
}
export function useUserProjectsPageViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProjectsPageViewerQuery,
    UserProjectsPageViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserProjectsPageViewerQuery,
    UserProjectsPageViewerQueryVariables
  >(UserProjectsPageViewerDocument, options);
}
export type UserProjectsPageViewerQueryHookResult = ReturnType<
  typeof useUserProjectsPageViewerQuery
>;
export type UserProjectsPageViewerLazyQueryHookResult = ReturnType<
  typeof useUserProjectsPageViewerLazyQuery
>;
export type UserProjectsPageViewerQueryResult = Apollo.QueryResult<
  UserProjectsPageViewerQuery,
  UserProjectsPageViewerQueryVariables
>;
export const UserProjectsPageCreateProjectDocument = gql`
  mutation userProjectsPageCreateProject {
    createProject {
      id
    }
  }
`;
export type UserProjectsPageCreateProjectMutationFn = Apollo.MutationFunction<
  UserProjectsPageCreateProjectMutation,
  UserProjectsPageCreateProjectMutationVariables
>;

/**
 * __useUserProjectsPageCreateProjectMutation__
 *
 * To run a mutation, you first call `useUserProjectsPageCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProjectsPageCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProjectsPageCreateProjectMutation, { data, loading, error }] = useUserProjectsPageCreateProjectMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserProjectsPageCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserProjectsPageCreateProjectMutation,
    UserProjectsPageCreateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserProjectsPageCreateProjectMutation,
    UserProjectsPageCreateProjectMutationVariables
  >(UserProjectsPageCreateProjectDocument, options);
}
export type UserProjectsPageCreateProjectMutationHookResult = ReturnType<
  typeof useUserProjectsPageCreateProjectMutation
>;
export type UserProjectsPageCreateProjectMutationResult =
  Apollo.MutationResult<UserProjectsPageCreateProjectMutation>;
export type UserProjectsPageCreateProjectMutationOptions =
  Apollo.BaseMutationOptions<
    UserProjectsPageCreateProjectMutation,
    UserProjectsPageCreateProjectMutationVariables
  >;
export const RegistrationPageCurrentTempUserProfileDocument = gql`
  query registrationPageCurrentTempUserProfile {
    currentTempUserProfile {
      id
      nickname
      avatarUrl
    }
  }
`;

/**
 * __useRegistrationPageCurrentTempUserProfileQuery__
 *
 * To run a query within a React component, call `useRegistrationPageCurrentTempUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPageCurrentTempUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationPageCurrentTempUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistrationPageCurrentTempUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegistrationPageCurrentTempUserProfileQuery,
    RegistrationPageCurrentTempUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegistrationPageCurrentTempUserProfileQuery,
    RegistrationPageCurrentTempUserProfileQueryVariables
  >(RegistrationPageCurrentTempUserProfileDocument, options);
}
export function useRegistrationPageCurrentTempUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrationPageCurrentTempUserProfileQuery,
    RegistrationPageCurrentTempUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegistrationPageCurrentTempUserProfileQuery,
    RegistrationPageCurrentTempUserProfileQueryVariables
  >(RegistrationPageCurrentTempUserProfileDocument, options);
}
export type RegistrationPageCurrentTempUserProfileQueryHookResult = ReturnType<
  typeof useRegistrationPageCurrentTempUserProfileQuery
>;
export type RegistrationPageCurrentTempUserProfileLazyQueryHookResult =
  ReturnType<typeof useRegistrationPageCurrentTempUserProfileLazyQuery>;
export type RegistrationPageCurrentTempUserProfileQueryResult =
  Apollo.QueryResult<
    RegistrationPageCurrentTempUserProfileQuery,
    RegistrationPageCurrentTempUserProfileQueryVariables
  >;
export const RegistrationPageAllPrefecturesDocument = gql`
  query registrationPageAllPrefectures {
    allPrefectures {
      id
      name
      cities {
        id
        name
      }
    }
  }
`;

/**
 * __useRegistrationPageAllPrefecturesQuery__
 *
 * To run a query within a React component, call `useRegistrationPageAllPrefecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPageAllPrefecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationPageAllPrefecturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistrationPageAllPrefecturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegistrationPageAllPrefecturesQuery,
    RegistrationPageAllPrefecturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegistrationPageAllPrefecturesQuery,
    RegistrationPageAllPrefecturesQueryVariables
  >(RegistrationPageAllPrefecturesDocument, options);
}
export function useRegistrationPageAllPrefecturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrationPageAllPrefecturesQuery,
    RegistrationPageAllPrefecturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegistrationPageAllPrefecturesQuery,
    RegistrationPageAllPrefecturesQueryVariables
  >(RegistrationPageAllPrefecturesDocument, options);
}
export type RegistrationPageAllPrefecturesQueryHookResult = ReturnType<
  typeof useRegistrationPageAllPrefecturesQuery
>;
export type RegistrationPageAllPrefecturesLazyQueryHookResult = ReturnType<
  typeof useRegistrationPageAllPrefecturesLazyQuery
>;
export type RegistrationPageAllPrefecturesQueryResult = Apollo.QueryResult<
  RegistrationPageAllPrefecturesQuery,
  RegistrationPageAllPrefecturesQueryVariables
>;
export const RegistrationPageRegisterUserDocument = gql`
  mutation registrationPageRegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      id
    }
  }
`;
export type RegistrationPageRegisterUserMutationFn = Apollo.MutationFunction<
  RegistrationPageRegisterUserMutation,
  RegistrationPageRegisterUserMutationVariables
>;

/**
 * __useRegistrationPageRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegistrationPageRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPageRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationPageRegisterUserMutation, { data, loading, error }] = useRegistrationPageRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrationPageRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationPageRegisterUserMutation,
    RegistrationPageRegisterUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationPageRegisterUserMutation,
    RegistrationPageRegisterUserMutationVariables
  >(RegistrationPageRegisterUserDocument, options);
}
export type RegistrationPageRegisterUserMutationHookResult = ReturnType<
  typeof useRegistrationPageRegisterUserMutation
>;
export type RegistrationPageRegisterUserMutationResult =
  Apollo.MutationResult<RegistrationPageRegisterUserMutation>;
export type RegistrationPageRegisterUserMutationOptions =
  Apollo.BaseMutationOptions<
    RegistrationPageRegisterUserMutation,
    RegistrationPageRegisterUserMutationVariables
  >;
